<template>
  <modal
    :name="`${CANCEL_REASON_MODAL}_${bookingNumber}`"
    :adaptive="true"
    height="auto"
    :width="480"
    @closed="close()"
    style="z-index: 2000"
  >
    <ValidationObserver ref="modalCancelReasonObserver" slim>
      <div class="modal-container w-100">
        <div class="d-flex align-items-center justify-content-between p-1">
          <p class="f-16 text-black font-600 mb-0">Cancel Booking</p>
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              cursor-pointer
            "
            style="
              width: 24px;
              height: 24px;
              background-color: rgb(228, 232, 237);
            "
            @click="close()"
          >
            <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
          </div>
        </div>
        <hr class="m-0" />
        <div class="p-1">
          <div
            v-for="(enumValue, enumValueIdx) in Object.keys(cancelReasonEnum)"
            :key="enumValueIdx"
            class="d-flex align-items-center cursor-pointer"
            :style="`
              gap: 8px;
              padding: 14px 0;
              border-bottom: ${enumValueIdx === Object.keys(cancelReasonEnum).length - 1
                ? '0' : '1px'} solid #E4E8ED;
            `"
            @click="selectedCancelReasonEnum = enumValue"
          >
            <div
              class="input-radio"
              :class="{
                'input-radio-selected': enumValue === selectedCancelReasonEnum
              }"
            />
            <p class="mb-0 f-14">{{ cancelReasonEnum[enumValue] }}</p>
          </div>
          <template v-if="selectedCancelReasonEnum === 'OTHER_REASON'">
            <ValidationProvider
              name="cancel reason"
              v-slot="{ errors }"
              :rules="{
                min: 11,
                max: 120,
                required: selectedCancelReasonEnum === 'OTHER_REASON',
              }"
              slim
            >
              <textarea
                  maxlength="120"
                  rows="4"
                  class="form-control"
                  :class="{ 'validation-warning': errors[0] }"
                  placeholder="Write the reason"
                  v-model="otherReason"
                  style="resize: none;"
              >
              </textarea>
              <div class="f-12 d-flex align-items-center justify-content-between" style="gap: 1rem; margin-top: 4px;">
                <div class="text-danger" v-if="errors[0]">{{ errors[0] }}</div>
                <div
                  style="flex: 1 0 auto;"
                  class="text-right"
                  :class="{ 'text-danger': errors[0] }"
                >
                    {{ otherReason === null ? 0 : otherReason.length }}/120
                </div>
              </div>
            </ValidationProvider>
          </template>
        </div>
        <div class="p-1" style="background-color: #f2f4f6;">
          <button
            class="btn btn-save rounded w-100 m-0"
            @click="cancelBooking()"
            :disabled="
              !selectedCancelReasonEnum ||
              (selectedCancelReasonEnum === 'OTHER_REASON' &&
                (otherReason === null ||
                (otherReason && otherReason.trimStart().length < 11))
              )
            "
          >
            Submit Cancellation
          </button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>
<script>
const CANCEL_REASON_MODAL = 'cancel-reason-modal';

export default {
  props: {
    isOpen: Boolean,
    bookingNumber: String,
  },
  data() {
    return {
      cancelReasonEnum: {
        'CHANGE_TREATMENT_TIME': 'Ingin ubah jadwal treatment saya',
        'CHANGE_TREATMENT_DETAIL': 'Ingin ubah pilihan treatment saya',
        'CHANGE_DOCTOR': 'Ingin ubah dokter terpilih',
        'OTHER_REASON': 'Alasan lainnya',
      },
      selectedCancelReasonEnum: null,
      otherReason: null,
      CANCEL_REASON_MODAL,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async cancelBooking() {
      const isValid = await this.$refs.modalCancelReasonObserver.validate();
      if (isValid) {
        let cancelReasonValue = this.cancelReasonEnum[this.selectedCancelReasonEnum];
        if (this.selectedCancelReasonEnum === 'OTHER_REASON') {
          cancelReasonValue = this.otherReason;
        }
        this.axios
        .patch(`/v2/clinical/offline-clinic-booking/${this.bookingNumber}`, {
          status: 'CANCELED',
          cancelReason: cancelReasonValue,
        })
        .then((res) => {
          this.$swal.fire({
            title: res.data.message,
            text: 'Booking canceled',
            icon: 'success',
            confirmButtonColor: '#0036A0',
            confirmButtonText: 'OK'
          });
          this.close();
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.$emit('save');
        });
      }
    },
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.selectedCancelReasonEnum = null;
        this.otherReason = null;
        this.$modal.show(`${CANCEL_REASON_MODAL}_${this.bookingNumber}`);
      } else {
        this.$modal.hide(`${CANCEL_REASON_MODAL}_${this.bookingNumber}`);
      }
    },
  },
}
</script>

<style scoped>
.btn-save {
  color: #ffffff;
  background-color: #0072cd;
}

.btn-save:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}

.input-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #BCC0D1;
  border-radius: 100%;
  cursor: pointer;
}

.input-radio.input-radio-selected {
  border-color: #0072CD;
}

.input-radio.input-radio-selected::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #0072CD;
}
</style>