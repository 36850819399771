<template>
    <div class="mw-100 p-2">
        <ValidationObserver ref="observer" slim>
            <div class="row">
                <div class="col-xl-7 col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <p class="f-16 font-600">Ubah Profile</p>
                            <hr>
                            <div class="row">
                                <div class="col-lg-4 col-xs-12 text-center mb-2">
                                    <div class="profile-avatar">
                                        <img :src="model.avatar ? model.avatar:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                        <div class="edit-avatar">
                                            <label for="file-upload" class="btn-edit">
                                                <i class="fa fa-pencil"></i>
                                            </label>
                                            <input id="file-upload" class="input-file-avatar" type="file" v-on:change="handleFileUpload">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-xs-12">
                                    <ValidationProvider name="email" v-slot="{ errors }" rules="required|email" slim>
                                        <div class="d-flex flex-column gap-0.25">
                                            <p class="mb-0 f-12 font-600">Email <span class="text-danger">*</span></p>
                                            <InputText v-model="model.email" placeholder="Masukkan Email" disabled />
                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="nama" v-slot="{ errors }" rules="required" slim>
                                        <div class="mt-1 d-flex flex-column gap-0.25">
                                            <p class="mb-0 f-12 font-600">Nama <span class="text-danger">*</span></p>
                                            <InputText
                                                v-model="model.fullName"
                                                placeholder="Masukkan Nama"
                                                iconClass="fa-user"
                                            />
                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        name="no. telepon"
                                        v-slot="{ errors }"             
                                        :rules="{ regex: /^08[1-9][0-9]{7,11}$/ }"
                                        slim
                                    >
                                        <div class="mt-1 d-flex flex-column gap-0.25">
                                            <p class="mb-0 f-12 font-600">No. Telepon</p>
                                            <InputText
                                                v-model="model.mobileNumber"
                                                :numberOnly="true"
                                                placeholder="Masukkan No. Telepon"
                                            />
                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Jenis Kelamin</p>
                                        <div class="d-flex gap-1">
                                            <button 
                                                type="button" 
                                                class="btn-gender" 
                                                :class="(model && model.gender === 'M') ? 'active' : ''"
                                                @click="setGender('M')"
                                            >
                                                <div class="d-flex align-items-center gap-0.25">
                                                    <img
                                                        src="/assets/img/icon/male-gender-icon.svg"
                                                        width="20"
                                                        height="20"
                                                        alt=""
                                                    />
                                                    <span>Laki-laki</span>
                                                </div>
                                            </button>
                                            <button 
                                                type="button" 
                                                class="btn-gender" 
                                                :class="(model && model.gender === 'F') ? 'active' : ''"
                                                @click="setGender('F')"
                                            >
                                                <div class="d-flex align-items-center gap-0.25">
                                                    <img
                                                        src="/assets/img/icon/female-gender-icon.svg"
                                                        width="20"
                                                        height="20"
                                                        alt=""
                                                    />
                                                    <span>Perempuan</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">SIP</p>
                                        <InputText
                                            v-model="model.sip"
                                            placeholder="Masukkan SIP"
                                        />
                                    </div>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">STR</p>
                                        <InputText
                                            v-model="model.str"
                                            placeholder="Masukkan STR"
                                        />
                                    </div>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Nama Klinik</p>
                                        <InputSelect
                                            v-model="model.offlineClinic"
                                            :options="clinicNameOptions"
                                            trackBy="id"
                                            label="name"
                                            placeholder="Pilih Nama Klinik"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-container">
                        <router-link to="/" class="btn btn-cancel rounded m-0">Batal</router-link>
                        <button class="btn btn-save rounded m-0" :disabled="isLoading" @click="save()">
                            <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import InputText from '@/components/InputText';
import InputSelect from '@/components/InputSelect';

export default {
    components: {
        InputText,
        InputSelect,
    },
    data() {
        return {
            model: {
                id: null,
                email: "",
                fullName: "",
                mobileNumber: "",
                sip: "",
                str: "",
                avatar: "",
                gender: null,
                admin: {}
            },
            temp_photo: null,
            clinicNameOptions: [],
            isLoading: false
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
    },
    mounted() {
        this.getClinicNameOptions();
        if (this.account) {
            this.model = {
                id: this.account.id,
                email: this.account.email,
                fullName: this.account.admin.fullName,
                mobileNumber: this.account.admin.mobileNumber,
                sip: this.account.admin.sip,
                str: this.account.admin.str,
                avatar: this.account.admin.avatar,
                gender: this.account.admin.gender,
                offlineClinic: this.account.admin.offlineClinic,
                admin: this.account.admin,
            };
        }
    },
    watch: {
        account: function(account) {
            if (account) {
                this.model = {
                    id: account.id,
                    email: account.email,
                    fullName: account.admin.fullName,
                    mobileNumber: account.admin.mobileNumber,
                    sip: account.admin.sip,
                    str: account.admin.str,
                    avatar: account.admin.avatar,
                    gender: account.admin.gender,
                    offlineClinic: account.admin.offlineClinic,
                    admin: account.admin,
                };
            }
        }
    },
    methods: {
        getClinicNameOptions() {
            this.axios.get('/v2/misc/offline-clinics')
                .then((res) => {
                    this.clinicNameOptions = res.data.data.rows;
                })
        },
        setGender(value) {
            this.model.gender = value;
            this.$forceUpdate();
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid) {
                let data = {
                    email: _.model.email,
                    firstName: _.model.fullName.split(' ')[0],
                    lastName: _.model.fullName.split(' ').filter( (n,i) => i > 0 ).join(' '),
                    mobileNumber: _.model.mobileNumber,
                    sip: _.model.sip,
                    str: _.model.str,
                    avatar: _.model.avatar,
                    gender: _.model.gender || undefined,
                    offlineClinicId: _.model.offlineClinic ? _.model.offlineClinic.id : null,
                }
                _.isLoading = true
                _.axios.put('admins/' + _.model.admin.id, data)
                    .then(() => {
                        _.$swal.fire({
                            title: 'Data Saved!',
                            text: 'Profile Updated',
                            icon: 'success',
                            confirmButtonColor: '#0036A0',
                            confirmButtonText: 'OK'
                        })

                        _.isLoading = false
                        this.$store.dispatch('getMe');
                        _.$router.push('/')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                        _.isLoading = false
                    })
            }
        },
        handleFileUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('folder', 'avatars')
            formData.append('file', this.file)

            this.axios.post('/misc/upload',
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                }).then(resp => {
                    if ( resp.data.success ) {
                      let file = resp.data.data.file
                      this.model.avatar = file
                    } else {
                      this.model.avatar = 'https://via.placeholder.com/150?text=dummy+image'
                    }
                }).catch(err => {
                    console.log(err.response)
                })
          },
    }
}
</script>

<style scoped>
.gap-0\.25 {
    gap: 0.25rem;
}

.gap-1 {
    gap: 1rem;
}

.btn-gender {
    width: 100%;
    height: 40px;
    border: 1px solid #bcc0d1;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-inline: 12px;
}

.btn-gender.active {
    border-color: #0072CD;
    background-color: #DDEDFA;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.btn-cancel {
    border-radius: 8px;
    border: 1px solid #bcc0d1;
    color: #5b5e62 !important;
    background-color: #ffffff;
}

.btn-save {
    color: #ffffff;
    background-color: #0072cd;
    padding: 12.6px 48px;
}

.btn-save:hover {
    color: #ffffff;
}
</style>